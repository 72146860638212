var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CreateCard",
    {
      attrs: {
        loading: _vm.loading,
        errorMessage: _vm.message,
        title: _vm.$t("pages.admin.AdminVoucherIndex.createTitle")
      },
      on: { handleSubmit: _vm.handleSubmit }
    },
    [
      _c("ValidatedSelect", {
        attrs: {
          translationKey: "voucher.voucherDiscountType",
          field: _vm.$v.voucher.discountType,
          value: _vm.$v.voucher.discountType.$model,
          options: _vm.availableVoucherDiscountTypeOptions
        },
        on: {
          "update:value": function($event) {
            return _vm.$set(_vm.$v.voucher.discountType, "$model", $event)
          }
        }
      }),
      _vm.voucher.discountType == "Percentage"
        ? _c("ValidatedInput", {
            attrs: {
              translationKey: "voucher.percentage",
              field: _vm.$v.voucher.percentage,
              value: _vm.$v.voucher.percentage.$model
            },
            on: {
              "update:value": function($event) {
                return _vm.$set(_vm.$v.voucher.percentage, "$model", $event)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "append-content",
                  fn: function() {
                    return [_vm._v(" % ")]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2419473106
            )
          })
        : _vm._e(),
      _vm.voucher.discountType == "Amount"
        ? _c("ValidatedInput", {
            attrs: {
              translationKey: "voucher.initialAmount",
              field: _vm.$v.voucher.initialAmount,
              value: _vm.$v.voucher.initialAmount.$model
            },
            on: {
              "update:value": function($event) {
                return _vm.$set(_vm.$v.voucher.initialAmount, "$model", $event)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "append-content",
                  fn: function() {
                    return [_c("CIcon", { attrs: { name: "cis-euro" } })]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              802186552
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }